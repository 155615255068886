exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-archive-jsx": () => import("./../../../src/pages/archive.jsx" /* webpackChunkName: "component---src-pages-archive-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-podcast-jsx": () => import("./../../../src/pages/podcast.jsx" /* webpackChunkName: "component---src-pages-podcast-jsx" */),
  "component---src-pages-schedule-jsx": () => import("./../../../src/pages/schedule.jsx" /* webpackChunkName: "component---src-pages-schedule-jsx" */),
  "component---src-pages-speakers-jsx": () => import("./../../../src/pages/speakers.jsx" /* webpackChunkName: "component---src-pages-speakers-jsx" */),
  "component---src-pages-sponsor-jsx": () => import("./../../../src/pages/sponsor.jsx" /* webpackChunkName: "component---src-pages-sponsor-jsx" */),
  "component---src-pages-team-jsx": () => import("./../../../src/pages/team.jsx" /* webpackChunkName: "component---src-pages-team-jsx" */),
  "component---src-pages-track-one-jsx": () => import("./../../../src/pages/track-one.jsx" /* webpackChunkName: "component---src-pages-track-one-jsx" */),
  "component---src-pages-track-two-jsx": () => import("./../../../src/pages/track-two.jsx" /* webpackChunkName: "component---src-pages-track-two-jsx" */),
  "component---src-pages-workshop-argo-jsx": () => import("./../../../src/pages/workshop-argo.jsx" /* webpackChunkName: "component---src-pages-workshop-argo-jsx" */),
  "component---src-pages-workshop-cilium-jsx": () => import("./../../../src/pages/workshop-cilium.jsx" /* webpackChunkName: "component---src-pages-workshop-cilium-jsx" */),
  "component---src-pages-workshop-go-jsx": () => import("./../../../src/pages/workshop-go.jsx" /* webpackChunkName: "component---src-pages-workshop-go-jsx" */),
  "component---src-pages-workshop-kubernetes-jsx": () => import("./../../../src/pages/workshop-kubernetes.jsx" /* webpackChunkName: "component---src-pages-workshop-kubernetes-jsx" */),
  "component---src-pages-workshops-jsx": () => import("./../../../src/pages/workshops.jsx" /* webpackChunkName: "component---src-pages-workshops-jsx" */),
  "component---src-templates-static-jsx-content-file-path-home-runner-work-kcd-utrecht-website-kcd-utrecht-website-content-static-pages-data-privacy-md": () => import("./../../../src/templates/static.jsx?__contentFilePath=/home/runner/work/kcd-utrecht-website/kcd-utrecht-website/content/static-pages/data-privacy.md" /* webpackChunkName: "component---src-templates-static-jsx-content-file-path-home-runner-work-kcd-utrecht-website-kcd-utrecht-website-content-static-pages-data-privacy-md" */),
  "component---src-templates-static-jsx-content-file-path-home-runner-work-kcd-utrecht-website-kcd-utrecht-website-content-static-pages-mission-statement-md": () => import("./../../../src/templates/static.jsx?__contentFilePath=/home/runner/work/kcd-utrecht-website/kcd-utrecht-website/content/static-pages/mission-statement.md" /* webpackChunkName: "component---src-templates-static-jsx-content-file-path-home-runner-work-kcd-utrecht-website-kcd-utrecht-website-content-static-pages-mission-statement-md" */)
}

